<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobLevel.nameKh")
                }}</label>
                <input
                    v-model.trim="model.position_level_name_kh"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('position_level_name_kh')
                    }"
                    ref="position_level_name_kh"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('position_level_name_kh')"
                >
                    {{ errors.first("position_level_name_kh") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobLevel.nameEn")
                }}</label>
                <input
                    v-model.trim="model.position_level_name_en"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('position_level_name_en')
                    }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('position_level_name_en')"
                >
                    {{ errors.first("position_level_name_en") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobLevel.order")
                }}</label>
                <input
                    v-model.trim="model.level_order"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('level_order') }"
                    ref="level_order"
                />
                <div class="invalid-feedback" v-if="errors.has('level_order')">
                    {{ errors.first("level_order") }}
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "job-levelForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                position_level_name_kh: null,
                position_level_name_en: null,
                level_order: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/jobLevel", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/jobLevel/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/jobLevel/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/jobLevel/update", {
                    id: this.edit_data.position_level_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.position_level_name_kh.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.position_level_name_kh = this.edit_data.position_level_name_kh;
                this.model.position_level_name_en = this.edit_data.position_level_name_en;
                this.model.level_order = this.edit_data.level_order;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.position_level_name_kh = null;
            this.model.position_level_name_en = null;
            this.model.level_order = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "JOB LEVEL",
                desc: not.text
            });
        }
    }
};
</script>
